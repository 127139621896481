// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shipping_3Oh7t{align-items:center;display:flex;flex-wrap:wrap;gap:0 3px;line-height:2rem;width:100%}.free_moB25{font-weight:700;text-transform:uppercase}.separator_2gH4L{display:flex;gap:4px}.separator_2gH4L:before{content:\"|\";display:block}.description_FEtxs{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shipping": "shipping_3Oh7t",
	"free": "free_moB25",
	"separator": "separator_2gH4L",
	"description": "description_FEtxs"
};
module.exports = ___CSS_LOADER_EXPORT___;
