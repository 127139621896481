// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".child_ogrcc{display:grid;grid-template-columns:32px 1fr;grid-gap:8px;gap:8px}.child__protection_eUhPt{grid-column:2/-1}.child__protection_eUhPt.chosen{margin:-4px 0 0 4px}.cartPage_7\\+nc3 .child_ogrcc{gap:10px 4px}@media only screen and (min-width:1264px){.cartPage_7\\+nc3 .child__protection_eUhPt>div{display:grid;grid-template-areas:\"icon name button price\";grid-template-columns:24px 1fr 212px 96px}.cartPage_7\\+nc3 .child__protection_eUhPt>div .v-btn{margin:auto}}.description_BrW8F{align-items:flex-start;display:flex;gap:4px;grid-column:2/-1}.img_01uvJ,.qty_\\+uUhr{flex-shrink:0}.text_1rTOK{flex-grow:1;line-height:20px;margin-top:5px}.img_01uvJ{grid-column:1/2;grid-row:1/-1}.qty_\\+uUhr{align-items:center;background:var(--gray-7);border-radius:99em;display:flex;gap:.4rem;height:28px;justify-content:center;margin:2px;min-width:28px;padding:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"child": "child_ogrcc",
	"child__protection": "child__protection_eUhPt",
	"cartPage": "cartPage_7+nc3",
	"description": "description_BrW8F",
	"img": "img_01uvJ",
	"qty": "qty_+uUhr",
	"text": "text_1rTOK"
};
module.exports = ___CSS_LOADER_EXPORT___;
