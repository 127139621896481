// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const FRAGMENT_EMPTY_DATA = gql`
  fragment fragmentEmptyData on emptySearchData {
    categories {
      id
      name
      slug
      image {
        alt
        src
      }
    }
    isSyteAI
  }
`;

const FRAGMENT_FILTER_ITEM_BASE = gql`
 fragment fragmentCatalogSearchFilterBlockItemBase on catalogSearchFilterBlockItemIterface {
  itemCount
  attrLabel
  attrValue
  virtualCategoryLink
  ... on facetCheckBoxCat {
    disabled
  }
 }
`;

export const FRAGMENT_FILTER_ITEM = gql`
    ${FRAGMENT_FILTER_ITEM_BASE}
    fragment fragmentCatalogSearchFilterBlockItem on catalogSearchFilterBlockItemIterface {
        ... fragmentCatalogSearchFilterBlockItemBase
        ... on catalogSearchFacetPrice {
          from
          to
        }
        ... on facetCheckBoxCat {
          child {
            ... fragmentCatalogSearchFilterBlockItemBase
          }
          disabled
        }
    }
`;

export const FRAGMENT_BANNER = gql`
    fragment fragmentBanner on promoData {
        bannerImage
        bannerImageAlt
        url
    }
`;
export const FRAGMENT_IMAGE = gql`
    fragment fragmentImage on catalogSearchImage {
        style
        alt
        src
        sources {
          media
          srcset
        }
        classes
    }
`;

export const FRAGMENT_IMAGE_BRAND = gql`
    fragment fragmentImageBrand on BrandImageType {
        style
        alt
        src
        sources {
          media
          srcset
        }
        classes
    }
`;

export const FRAGMENT_FILTER_BLOCK = gql`
  ${FRAGMENT_FILTER_ITEM}
  fragment fragmentCatalogSearchFilterBlock on catalogSearchFilterBlock {
    header: title
    attrCode
    facets {
      ...fragmentCatalogSearchFilterBlockItem
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_FILTER_ITEM1 = gql`
  ${FRAGMENT_FILTER_ITEM}
  fragment fragmentCatalogSearchFilterBlockItem1 on catalogSearchFilterBlockItemIterface {
    ...fragmentCatalogSearchFilterBlockItem
    child {
      ...fragmentCatalogSearchFilterBlockItem
    }
  }
`;
// eslint-disable-next-line no-unused-vars
export const FRAGMENT_DYNAMIC = gql`
    fragment fragmentDynamicAttribute on catalogSearchPrimaryDynamicAttributeInterface {
        __typename
        type
        ... on catalogSearchPrimaryDynamicAttributeTextType {
            count
            label
        }
        ...on catalogSearchPrimaryDynamicAttributeThumbnailType {
            additional
            items {
                image
                webId
                classes
            }
        }
        ... on catalogSearchPrimaryDynamicAttributeSwatchThumbnailType {
            additional
            items {
                image
                webId
                classes
            }
        }
    }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT = gql`
  ${FRAGMENT_IMAGE}
  ${FRAGMENT_DYNAMIC}
  fragment fragmentCatalogProduct on catalogSearchProductInterface {
    __typename
    id
    webId
    name
    labels
    brand {
      id
      name
    }
    cat_names
    slug
    url
    shippingType
    price {
      finalPrice
      msrp
      showMsrp
      getExcludePromo
      getSale
    }
    tags {
      allTags
      primaryDealTag
      primaryDealTagVisibility
      secondaryTag
      secondaryTagVisibility
      tertiaryTag
      tertiaryTagVisibility
      __typename
    }
    couponData {
      discount
      code
    }
    images {
      mainImage {
        ...fragmentImage
      }
      hoverImage {
        ...fragmentImage
      }
    }
    reviews {
      number
      rating
    }
    mattressData{
      comfort
      height
      type
      top_type
      label
    }
    ... on catalogSearchProductDynamicItem {
        dynamicAttribute {
            ...fragmentDynamicAttribute
        }
        secondaryDynamicAttribute {
            ...fragmentDynamicAttribute
        }
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_IMAGE = gql`
  fragment fragmentProductImage on ProductImageType {
    __typename
    style
    alt
    src
    sources {
      media
      srcset
    }
    classes
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_PRICE = gql`
  fragment fragmentPrice on ProductPriceType {
    price
    regularPrice
    finalPrice
    msrp
    relatedCustomPrice
    totalDiscount
    youSave
    selectionPrice
    discount
    excludePromo
    priceMatch
    priceProtection
    originalPrice
    clearanceQty
    priceValidUntil
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_TAG = gql`
  fragment fragmentTag on TagType {
    primary
    primaryVisibility
    secondary
    secondaryVisibility
    tertiary
    tertiaryVisibility
    banner
    bannerVisibility
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_GENERAL = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  ${FRAGMENT_PRODUCT_PRICE}
  ${FRAGMENT_PRODUCT_TAG}
  fragment fragmentProductGeneral on ProductGeneral {
    id
    webId
    bedTypeId
    mpn
    sku
    masterSku
    name
    slug
    # productType
    typeId
    # typeGroup
    labels
    url
    image {
      ...fragmentProductImage
    }
    brand {
      id
      name
      slug
      url
      image {
        ...fragmentProductImage
      }
      isActive
    }
    collection {
      id
      name
      slug
    }
    price {
      ...fragmentPrice
    }
    tags {
      ...fragmentTag
    }
    coupon {
      discount
      discountType
      code
      minPurchase
    }
    category
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_ADDITIONAL = gql`
  fragment fragmentProductAdditional on ProductAdditional {
  showAffirm
  showKatapult
  outOfStock
  optionId
  selectionId
  mattressDesc {
    comfortLevel
    thickness
    type
    top
  }
  desc
  showDesc
  specs {
    name
    value
    url
    slug
  }
  weightAndDim
  delivery {
    type
    method
    date
    dateHtml
    isFreeShipping
  }
  canDisplayMattress
  canDisplayMattressPopup
  buildYourOwnEnabled
  visibility
  shopMore {
    key
    title
    url
  }
  gtin
  dimensions {
    height
    width
    weight
    depth
  }
  isUseInGoogleFeed
}
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_SELECTION = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  fragment fragmentProductSelection on BCOSelection {
    id
    title
    optTitle
    price
    selectionPrice
    qtyIncrement
    qty
    defaultQty
    default
    productTitle
    productType
    setOf
    brandId
    product {
      id
      webId
      name
      slug
      bedTypeId
      image {
        ...fragmentProductImage
      }
      thumbnail{
        ...fragmentProductImage
      }
      price {
        msrp
        price
        finalPrice
        regularPrice
      }
      labels
      visibility
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_CONFIGURABLE_ITEM = gql`
  ${FRAGMENT_PRODUCT_SELECTION}
  fragment fragmentConfigurableItem on BundleConfigurable {
    required{
      isRequired
      pieceCount
      optionId
      groupingTitle
      viewType
      selections {
        ...fragmentProductSelection
      }
    }
    additional{
      isRequired
      pieceCount
      optionId
      groupingTitle
      viewType
      selections {
        ...fragmentProductSelection
      }
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_SHIPPING_AND_RETURN = gql`
  fragment fragmentShippingAndReturn on ShippingAndReturnType {
    shipping {
      title
      subtitle
    }
    return {
      title
      subtitle
      subtitleSlug
      isMattress
      merchantReturnDays
      url
    }
    popupType
    urlShippingDetails
    transitTime {
      maxValue
      minValue
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_RELATED_SEARCH = gql`
  fragment fragmentRelatedSearch on RelatedSearchType {
    link
    title
    internalRoute
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_AFFIRM_TYPE = gql`
  fragment fragmentAffirm on AffirmType {
    key
    url
    minThreshold
    maxThreshold
    configurableOption
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_KATAPULT_TYPE = gql`
  fragment fragmentKatapult on KatapultType {
    key
    environment
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_RATING = gql`
  fragment fragmentRating on ProductRating {
    number
    rating
    votes{
      voteName
      voteCount
      votePercent
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_ACCESSORIES = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  fragment fragmentAccessories on CollectionProduct {
    id
    webId
    name
    slug
    image{
      ...fragmentProductImage
    }
    price{
      price
      regularPrice
      originalPrice
      excludePromo
      clearanceQty
    }
    tags {
      tertiary
      tertiaryVisibility
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_RECOMMENDEDMATTRESS_PARENTPRODUCT = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  ${FRAGMENT_PRODUCT_RATING}
  fragment fragmentRecommendedmattressParentProduct on CollectionProduct {
    id
    webId
    name
    slug
    brand
    image{
      ...fragmentProductImage
    }
    rating{
      ...fragmentRating
    }
    productImages {
      ...fragmentProductImage
    }
    productThumbImages {
      ...fragmentProductImage
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_RECOMMENDEDMATTRESS_PRODUCT = gql`
  fragment fragmentRecommendedmattressProduct on CollectionProduct {
    optionId
    selectionId
    price{
      price
      discount
      regularPrice
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_GROUPING_ATTRIBUTE = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  fragment fragmentGroupingAttribute on GroupingAttribute {
    id
    code
    name
    viewType
    values {
      id
      name
      image {
        ...fragmentProductImage
      }
      imageRaw {
        ...fragmentProductImage
      }
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_GALLERY = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  fragment fragmentProductGallery on ProductGallery {
    productImages {
      ...fragmentProductImage
    }
    productThumbImages {
      ...fragmentProductImage
    }
    youtubeLinks {
      __typename
      src
    }
    youtubeThumbLinks {
      __typename
      src
    }
    customerImages {
      ...fragmentProductImage
    }
    customerThumbImages {
      ...fragmentProductImage
    }
    solidButtonFlag
    webId
    mpn
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_GROUPING_PRODUCT_DATA = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  fragment fragmentGroupingProductData on GroupingProduct {
    general{
      id
      name
      bedTypeId
      slug
      # productType
      typeId
      labels
      image {
        ...fragmentProductImage
      }
      price {
        price
        regularPrice
        finalPrice
        selectionPrice
      }
    }
    additional {
      outOfStock
      selectionId
      optionId
      buildYourOwnEnabled
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_MFC_PRODUCT_DATA = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  fragment fragmentMfcProductData on GroupingProduct {
    general{
      id
      webId
      name
      slug
      labels
      image {
        ...fragmentProductImage
      }
      price {
        price
        regularPrice
        finalPrice
        msrp
        relatedCustomPrice
        youSave
        originalPrice
        clearanceQty
      }
    }
    additional{
      visibility
      outOfStock
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_GROUPING_ITEM = gql`
  ${FRAGMENT_GROUPING_PRODUCT_DATA}
  fragment fragmentGroupingItem on GroupingItem {
    firstAttribute
    secondAttribute
    isDefault
    product {
      ...fragmentGroupingProductData
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_FOUNDATION = gql`
  ${FRAGMENT_GROUPING_ATTRIBUTE}
  ${FRAGMENT_GROUPING_ITEM}
  fragment fragmentFoundation on FoundationType {
    attributes {
      ...fragmentGroupingAttribute
    }
    items {
      ...fragmentGroupingItem
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_GROUPING = gql`
  ${FRAGMENT_GROUPING_ATTRIBUTE}
  ${FRAGMENT_GROUPING_ITEM}
  ${FRAGMENT_FOUNDATION}
  fragment fragmentProductGrouping on ProductGrouping {
    attributes {
      ...fragmentGroupingAttribute
    }
    items {
      ...fragmentGroupingItem
    }
    foundation {
      ...fragmentFoundation
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_MORE_FROM_COLLECTION_ITEM = gql`
  ${FRAGMENT_MFC_PRODUCT_DATA}
  ${FRAGMENT_GROUPING_ATTRIBUTE}
  fragment fragmentMoreFromCollectionItem on MoreFromCollectionItem {
    product {
      ...fragmentMfcProductData
    }
    grouping{
      attributes {
        ...fragmentGroupingAttribute
      }
      items {
        firstAttribute
        secondAttribute
        product {
          ...fragmentMfcProductData
        }
      }
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_PRODUCT_MORE_FROM_COLLECTION = gql`
  ${FRAGMENT_PRODUCT_MORE_FROM_COLLECTION_ITEM}
  fragment fragmentMoreFromCollection on MoreFromCollectionProduct {
    showBanner
    isSpanLinks
    items{
      ...fragmentMoreFromCollectionItem
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const FRAGMENT_RECOMMENDEDMATTRESS = gql`
  ${FRAGMENT_RECOMMENDEDMATTRESS_PARENTPRODUCT}
  ${FRAGMENT_RECOMMENDEDMATTRESS_PRODUCT}
  fragment fragmentRedommendedMattress on RecommendedMattress {
    comfortLevel {
      id
      name
    }
    parentProduct {
      ...fragmentRecommendedmattressParentProduct
    }
    sizes {
      size {
        id
        name
      }
      product {
        ...fragmentRecommendedmattressProduct
      }
    }
  }
`;

export const FRAGMENT_REVIEW = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  ${FRAGMENT_PRODUCT_RATING}
  fragment fragmentReview on Review {
    images {
      ...fragmentProductImage
    }
    rating{
      ...fragmentRating
    }
  }
`;

export const FRAGMENT_REVIEW_ITEM = gql`
  ${FRAGMENT_PRODUCT_IMAGE}
  fragment fragmentReviewItem on ProductReviewItem {
    id
    text
    date
    summary
    rating
    isVerifiedPurchase
    helpfulCount
    address {
      city
      regionCode
    }
    author {
      name
    }
    product {
      name
      url
      slug
    }
    images {
      ...fragmentProductImage
    }
  }
`;

export const FRAGMENT_BRAND_REVIEW_ITEM = gql`
  ${FRAGMENT_IMAGE_BRAND}
  fragment fragmentBrandReviewItem on BrandReview {
    id
    text
    date
    summary
    rating
    helpfulCount
    isVerifiedPurchase
    address {
      city
      regionCode
    }
    author {
      name
    }
    product {
      name
      url
      slug
    }
    images {
      ...fragmentImageBrand
    }
  }
`;
