
import { mapState } from 'vuex';
import respUtility from '@/mixins/respUtility';
// eslint-disable-next-line import/no-named-as-default
import headerMenuOverlayUtility from '@/mixins/headerMenuOverlayUtility';
import { GET_CART_DATA } from '@/api/apolloCommonQueries';
import { UPDATE_ITEM_PROTECTION } from '@/api/apolloCartMutations';

/**
 * The minicart
 * @category [Header, Cart]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartWrapper />
 * @nuxt-listeners ['add-to-cart']
 */
export default {
  name: 'HeaderCartWrapper',
  mixins: [respUtility, headerMenuOverlayUtility],
  data: () => ({
    showPopup: false,
    cartItemId: null,
    popupItems: [],
    nodeId: 'header-cart',
    menu: false,
    isFocusedOnMenu: false,
  }),
  computed: {
    ...mapState({
      /** @returns {Object} - gql CartType */
      currentCart: (state) => state.cart.cart,
      /** @returns {Array} - [String] - product ids */
      newItems: (state) => state.cart.newItems || [],
    }),
    /** @returns {Array} - gql [CartItemType] */
    cartItems() {
      return this.currentCart?.items || [];
    },
    /** @returns {Object} - gql CartItemType */
    cartItem() {
      return this.cartItems.find((i) => i.itemId === this.cartItemId);
    },
    /** @returns {Number} - quantity of all products in the cart */
    totalQty() {
      return this.currentCart?.summaryCount || 0;
    },
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
  },
  watch: {
    '$device.isMobileOrTablet': function watchDevice() {
      if (this.menu) {
        this.menu = false;
      }
    },
  },
  created() {
    this.$nuxt.$on('add-to-cart', () => {
      if (!(this.$device.isMobileOrTablet || this.isTouchDevice)) {
        this.menu = true;
      }
    });
  },
  beforeDestroy() {
    this.$nuxt.$off('add-to-cart');
  },
  methods: {
    /** Open the protection popup for current cart's item */
    openPopup(items) {
      this.popupItems = items || [];
      if (items) {
        this.showPopup = !this.showPopup;
      }
    },
    cartClick() {
      if (this.$store.state.pageData.globalType === 'CHECKOUT_CART') {
        return;
      }
      if (this.$device.isMobileOrTablet || this.isTouchDevice) {
        this.$nuxt.$emit('change-global-loading', true);
        setTimeout(() => {
          this.$router.push('/checkout/cart');
          this.$nextTick(() => {
            this.$nuxt.$emit('change-global-loading', false);
          });
        }, 0);
      } else {
        this.menu = true;
      }
    },
    /** Close the onsite popup */
    closePopup() {
      this.showPopup = false;
    },
    /** Set, change or remove chosen onsite protection */
    async updateProtection(chosen) {
      if (!chosen) {
        return;
      }
      this.$nuxt.$emit('change-global-loading', true);
      try {
        await this.$graphql.default.request(
          UPDATE_ITEM_PROTECTION,
          { protectionPlan: Object.entries(chosen).map(([item, plan]) => ({ item, plan: plan || null })) },
        );
      } catch (e) {
        console.error(e);
      }
      await this.getCart();
      this.$nuxt.$emit('change-global-loading', false);
      this.closePopup();
    },
    async getCart() {
      try {
        const { cart } = await this.$graphql.default
          .request(GET_CART_DATA, { subtotal: this.currentCart?.subtotal || 0, webId: null }) || {};
        this.$store.commit('cart/setCart', cart?.minicart);
        this.$store.commit('cart/setPhone', cart?.phone?.phoneByTotal);
      } catch (e) {
        console.error(e);
      }
    },
    onFocusIn(event) {
      const baseSelectMenu = document.querySelector('.base-select-menu');
      const cartElement = this.$refs.cart;
      if (
        (baseSelectMenu && baseSelectMenu.contains(event.target))
        || (cartElement && cartElement.contains(event.target))
      ) {
        this.isFocusedOnMenu = true;
      } else {
        this.isFocusedOnMenu = false;
        this.menu = false;
      }
    },
  },
};
