
import respUtility from '~/mixins/respUtility';
/**
 * The main footer component
 * @category footer
 * @component
 * @example <caption>TheFooter usage (see code)</caption>
 * <TheFooter />
 */
export default {
  name: 'TheFooter',
  mixins: [respUtility],
  props: {
    footerData: {
      type: Object,
    },
  },
  data: () => ({
    footerLoading: 0,
    isFrozen: false,
    isTopButton: false,
  }),

  watch: {
    isRespMdAndUp() {
      this.changeTopButtonProps();
    },
    'footerData.footerMenu': function footerMenuWatcher() {
      this.$store.commit('setFooterRender', true);
    },
    $route(to) {
      if (to.path === '/all-furniture') {
        this.$store.commit('setFooterRender', true);
      }
    },
  },
  computed: {
    menu() {
      return {};
    },
  },
  mounted() {
    this.$taskManager.append(() => {
      this.changeTopButtonProps();
    });
    window.addEventListener('resize', this.changeTopButtonProps);
    window.addEventListener('scroll', this.changeTopButtonProps);
    // document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeTopButtonProps);
    window.removeEventListener('scroll', this.changeTopButtonProps);
    // document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    setFooterTop() {
      this.footerTop = this.$refs.footer && this.$refs.footer.offsetTop ? this.$refs.footer.offsetTop : 0;
    },
    /** Displays the scroll button or not depends on the scroll position */
    changeTopButtonProps() {
      const { scrollY, innerHeight } = window;
      this.isTopButton = scrollY > innerHeight * 0.25;
    },
    getViewportHeight() {
      if (window.innerHeight) {
        return window.innerHeight;
      }
      return document?.body?.offsetHeight || 0;
    },
    // handleVisibilityChange() {
    //   console.log('31331');
    //   if (document.visibilityState === 'visible') {
    //   // Modify footerData.footerMenu to trigger the watcher
    //     this.footerData.footerMenu = [...this.footerData.footerMenu];
    //   }
    // },
  },
};
