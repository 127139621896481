
/**
 * Component to build Alerts based on the v-alert
 * @category Alerts
 * @component
 * @example <caption>Alert usage (see code)</caption>
 * <BaseAlert type="success" content="text" dismissible @input="onCloseMsg(msg)" />
 * If content === 'DEFAULT ERROR' will be rendered a default error message
 */
export default {
  name: 'BaseAlert',
  props: {
    /** @prop {String} [content="text"] - Message's content - can be html String */
    content: {
      type: String,
      default: '',
    },
    /** @prop {String} [type="error"] -
     * Alert type (success, warning, error). Uses the contextual color and has a pre-defined icon. */
    type: {
      type: String,
      default: '',
    },
    /** @prop {Boolean} [permanent] -
     * Permanent (high-attention) message need explicit user action to hide
     * (click on close button or refreshing the page)
     * Low-attention messages disappear in 8 seconds even if users don’t click close button with some fade/slide effect
     */
    permanent: {
      type: Boolean,
      default: false,
    },
    additionalClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timeoutId: null,
      alertTimeout: 8000,
      defaultAlertProps: {
        text: true,
        closeIcon: '$vuetify.icons.close',
        dismissible: true,
      },
    };
  },
  computed: {
    /**
     * Compute alert's parameters depend on it's type.
     * @returns {{color: string, icon: string, style: string}|{color: string, style: string}}
     */
    alertType() {
      switch (this.type) {
        case 'success': return {
          style: 'alertSuccess',
          color: '#A8C658',
          icon: 'IconCheckmarkLightBlue-32x32',
          width: '32',
        };
        case 'warning': return {
          style: 'alertWarning',
          color: '#435FEE',
          icon: 'IconLowAlert-32x32',
          width: '32',
        };
        case 'error': return {
          style: 'alertError',
          color: '#FFE8E8',
          icon: 'IconLowAlert-32x32',
          width: '32',
        };
        case 'notice': return {
          style: 'alertNotice',
          color: '#435FEE',
        };
        case 'wishlist': return {
          style: 'alertWishList',
          color: '#FFFFFF',
          icon: 'IconWishList-24x24',
          width: '24',
        };
        default: return {
          style: 'alertDefault',
          color: '#D8D8D8',
        };
      }
    },
    /** Compute default content */
    contentWithDefaultValues() {
      switch (this.content) {
        case 'DEFAULT ERROR': return '<div class="default-error-message"><p class="error-header">Something goes wrong.</p><p>Please try again later.</p></div>';
        default: return this.content;
      }
    },
  },
  /** Turn on the timeout to hide the low-priority alert */
  mounted() {
    if (!this.permanent) {
      this.timeoutId = setTimeout(this.closeAlert, this.alertTimeout);
    }
  },
  /** Clear the timeout */
  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },
  methods: {
    /** Hide the alert */
    closeAlert() {
      if (this.$refs?.alert) {
        this.$refs.alert.toggle();
      }
    },
  },
};
