
/**
 * The minicart's item's bundle's items
 * @category [Header, Cart]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartItemSet :child-items-count="5" :child-items="childItems" />
 */
export default {
  name: 'HeaderCartItemSet',
  props: {
    /** @prop {Number} [:child-items-count="5"] -
     * The bundle's selected items' count */
    childItemsCount: {
      type: Number,
      default: 0,
    },
    /** @prop {Object} [:child-items="childItems"] - Bundle items */
    childItems: {
      type: Array,
      default() { return []; },
    },
    globalQty: {
      type: Number,
      default: 1,
    },
    /** @prop {Boolean} [mini-cart] - is the item inside the mini-cart or cart page */
    miniCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
  },
};
